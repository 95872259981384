import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from ".";
import { Transaction } from "../generated/graphql";

interface TransactionState {
  loading: boolean;
  transactions: Array<Transaction>;
}

const initialState: TransactionState = {
  loading: true,
  transactions: [],
};

export const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTransactions: (state, action: PayloadAction<Array<Transaction>>) => {
      state.loading = false;
      state.transactions = [...state.transactions, ...action.payload];
    },
    reset: (state) => {
      state.loading = false;
      state.transactions = [];
    },
  },
});

export const { loading, reset, setTransactions } = transactionSlice.actions;

export const transactionSelector = (state: RootState) => state.transactions;

export default transactionSlice.reducer;
