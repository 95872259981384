import { FC, lazy, ReactNode, Suspense } from "react";
import "./App.css";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { AnimatePresence } from "framer-motion";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import theme from "./theme";
import Navbar from "./shared/components/navbar";
import AuthGuard from "./guard/auth";
import { useAppSelector } from "./state/hooks";
import ConfirmEmail from "./pages/auth/confirmEmail/confirmEmail";
import BuyCable from "./pages/dashboard/buyCable";
import Privacy from "./pages/settings/privacy";

const Auth = lazy(() => import("./pages/auth/index"));
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const BuyAirtime = lazy(() => import("./pages/dashboard/buyAirtime"));
const BuyInternet = lazy(() => import("./pages/dashboard/buyDataBundle"));
const BuyElectricity = lazy(() => import("./pages/dashboard/buyElectricity"));
const Transaction = lazy(() => import("./pages/transaction/transaction"));
const TransactionChild = lazy(() => import("./pages/transaction/child"));
const Notification = lazy(() => import("./pages/notification/notification"));
const Settings = lazy(() => import("./pages/settings/settings"));
const ResetPassword = lazy(() => import("./pages/settings/resetPassword"));
const User = lazy(() => import("./pages/dashboard/user"));
const Account = lazy(() => import("./pages/settings/account"));
const SavedMeternumbers = lazy(
  () => import("./pages/settings/savedMeternumbers")
);

const LazyLoading: FC<{ children: ReactNode }> = ({ children }) => (
  <Suspense
    fallback={
      <Box
        width="100%"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    }
  >
    {children}
  </Suspense>
);

function App() {
  const location = useLocation();
  const userState = useAppSelector((state) => state.user);

  const toolbar = {
    minHeight: 56,
    "@media (minWidth): 600px": {
      minHeight: 64,
    },
    "@media (minWidth): 0px": {
      "@media (orientation: landscape)": {
        minHeight: 48,
      },
    },
  };

  return (
    <div style={{ display: "flex" }}>
      <ThemeProvider theme={theme}>
        {location.pathname.toString().indexOf("auth") === -1 &&
          userState.user && <Navbar />}
        <main style={{ flexGrow: "1", padding: theme.spacing(3) }}>
          <div style={toolbar} />
          <AnimatePresence>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              {/* auth */}
              <Route
                path="/auth/*"
                element={
                  <LazyLoading>
                    <Auth />
                  </LazyLoading>
                }
              />
              <Route path="/confirm-email" element={<ConfirmEmail />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route element={<AuthGuard />}>
                {/* dashboard */}
                <Route
                  path="/dashboard"
                  element={
                    <LazyLoading>
                      <Dashboard />
                    </LazyLoading>
                  }
                />
                <Route
                  path="/buy-airtime"
                  element={
                    <LazyLoading>
                      <BuyAirtime />
                    </LazyLoading>
                  }
                />
                <Route
                  path="/buy-cable"
                  element={
                    <LazyLoading>
                      <BuyCable />
                    </LazyLoading>
                  }
                />
                <Route
                  path="/buy-internet"
                  element={
                    <LazyLoading>
                      <BuyInternet />
                    </LazyLoading>
                  }
                />
                <Route
                  path="/buy-electricity"
                  element={
                    <LazyLoading>
                      <BuyElectricity />
                    </LazyLoading>
                  }
                />
                <Route
                  path="/transactions"
                  element={
                    <LazyLoading>
                      <Transaction />
                    </LazyLoading>
                  }
                />
                <Route
                  path="/notifications"
                  element={
                    <LazyLoading>
                      <Notification />
                    </LazyLoading>
                  }
                />
                <Route
                  path="/transactions/:id"
                  element={
                    <LazyLoading>
                      <TransactionChild />
                    </LazyLoading>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <LazyLoading>
                      <Settings />
                    </LazyLoading>
                  }
                />
                <Route
                  path="/reset-password"
                  element={
                    <LazyLoading>
                      <ResetPassword />
                    </LazyLoading>
                  }
                />
                <Route
                  path="/account"
                  element={
                    <LazyLoading>
                      <Account />
                    </LazyLoading>
                  }
                />
                <Route
                  path="/saved-meternumbers"
                  element={
                    <LazyLoading>
                      <SavedMeternumbers />
                    </LazyLoading>
                  }
                />

                <Route
                  path="/user"
                  element={
                    <LazyLoading>
                      <User />
                    </LazyLoading>
                  }
                />
              </Route>
            </Routes>
          </AnimatePresence>
        </main>
      </ThemeProvider>
    </div>
  );
}

export default App;
