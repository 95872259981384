import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from ".";
import { Meternumber } from "../generated/graphql";

interface MeternumberState {
  loading: boolean;
  meternumbers: Array<Meternumber>;
}

const initialState: MeternumberState = {
  loading: true,
  meternumbers: [],
};

export const meternumberSlice = createSlice({
  name: "meternumbers",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setMeternumbers: (state, action: PayloadAction<Array<Meternumber>>) => {
      state.loading = false;
      state.meternumbers = [...state.meternumbers, ...action.payload];
    },
    reset: (state) => {
      state.loading = false;
      state.meternumbers = [];
    },
  },
});

export const { loading, reset, setMeternumbers } = meternumberSlice.actions;

export const meternumberSelector = (state: RootState) => state.meternumbers;

export default meternumberSlice.reducer;
