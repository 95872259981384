import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from ".";
import { User } from "../generated/graphql";

interface UserState {
  loading: boolean;
  user?: Partial<User>;
}

const initialState: UserState = {
  loading: true,
  user: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    logIn: (state, action: PayloadAction<User>) => {
      state.loading = false;
      state.user = action.payload;
    },
    logOut: (state) => {
      state.loading = false;
      state.user = undefined;
    },
    setUser: (state, action: PayloadAction<Partial<User>>) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export const { loading, logIn, logOut, setUser } = userSlice.actions;

export const userSelector = (state: RootState) => state.user;

export default userSlice.reducer;
