import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const link = createHttpLink({
  uri:
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? process.env.REACT_APP_API_BASE_DEV
      : process.env.REACT_APP_API_BASE,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("PPNG_TOKEN");

  return {
    headers: {
      ...headers,
      user: `Bearer ${token}` ?? null,
      agent: "web",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

export default client;
