import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { isNull } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useConfirmEmailMutation } from "../../../generated/graphql";

export default function ConfirmEmail() {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const q = searchParams.get("q");

  const [confirmEmail] = useConfirmEmailMutation({
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!isNull(q)) {
      confirmEmail({
        variables: {
          query: q,
        },
      })
        .then(({ data, errors }) => {
          if (data?.confirmEmail.success) {
            window.location.href = "/dashboard";
          } else {
            enqueueSnackbar(data?.confirmEmail.message);
            setTimeout(() => {
              window.location.href = "/dashboard";
            }, 1500);
          }

          if (errors) {
            enqueueSnackbar(errors[0]?.originalError?.message);
            setTimeout(() => {
              window.location.href = "/dashboard";
            }, 1500);
          }
        })
        .catch(() => {
          enqueueSnackbar("Something went wrong, Please try again");
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 1500);
        });
    }
  }, []);

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
}
