import { ReactElement, useState } from "react";
import { isMobile, isAndroid, isIOS } from "react-device-detect";

import AppBar from "@mui/material/AppBar";
import BottomNavigation from "@mui/material/BottomNavigation";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Drawer from "@mui/material/Drawer";
import Paper from "@mui/material/Paper";
import Hidden from "@mui/material/Hidden";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import HomeOutlined from "@mui/icons-material/HomeOutlined";
import HistoryOutlined from "@mui/icons-material/HistoryOutlined";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import ExitToAppOutlined from "@mui/icons-material/ExitToAppOutlined";
import PhoneOutlined from "@mui/icons-material/PhoneOutlined";

import theme from "../../theme";
import { useAppSelector } from "../../state/hooks";

import logo from "../../assets/images/logos/logo32_color_.png";
import googlePlayIcon from "../../assets/images/logos/google_play_icon.png";

const drawerWidth = 240;

const style = {
  root: {
    display: "flex",
  },
  bottomNavigation: {
    width: "100%",
    bottom: 0,
    position: "fixed",
    backgroundColor: "white",
    zIndex: 1,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: `${drawerWidth}px`,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
    },
    zIndex: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: `${drawerWidth}px`,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  sidebar: {
    display: "flex",
  },
  box: {
    width: "300px",
    [theme.breakpoints.down("md")]: {
      width: "70px",
    },
  },
  avatar: {
    height: 50,
    width: 50,
  },
};

export default function Navbar(): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.user);
  const [open, setOpen] = useState<boolean>(true);
  const [value, setValue] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClose = () => setAnchorEl(null);

  const DRAWER = (
    <div>
      <div style={style.toolbar} />
      <Divider />
      <List style={{ width: "240px" }}>
        <ListItem
          component={NavLink}
          to="/dashboard"
          button
          key="Dashboard"
          color={location.pathname === "/dashboard" ? "primary" : ""}
        >
          <ListItemIcon>
            <HomeOutlined />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem
          component={NavLink}
          to="/transactions"
          button
          key="Transactions"
          color={location.pathname === "/transactions" ? "primary" : ""}
        >
          <ListItemIcon>
            <HistoryOutlined />
          </ListItemIcon>
          <ListItemText primary="Transactions" />
        </ListItem>
        <ListItem
          component={NavLink}
          to="/user"
          button
          key="Settings"
          color={location.pathname === "/user" ? "primary" : ""}
        >
          <ListItemIcon>
            <SettingsOutlined />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" sx={style.appBar}>
        {isMobile && open ? (
          <Card style={{ background: "#F5F5F5" }}>
            <CardContent>
              <Grid container>
                <Grid item xs={2}>
                  <IconButton onClick={() => setOpen(false)}>
                    <CloseOutlined />
                  </IconButton>
                </Grid>
                <Grid item xs={7}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Avatar
                        variant="rounded"
                        sx={style.avatar}
                        src={googlePlayIcon}
                        alt="icon"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="body2"
                        component="p"
                        style={{ fontWeight: "bold" }}
                      >
                        Open the Paypower App
                      </Typography>
                      <Typography variant="caption">
                        View in App for the best experience
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (isAndroid) {
                          window.location.href =
                            "https://play.google.com/store/apps/details?id=ng.paypower.android";
                        } else if (isIOS) {
                          window.location.href = "";
                        }
                      }}
                      color="primary"
                    >
                      Open
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ) : (
          ""
        )}
        <Toolbar>
          <Typography variant="h6" noWrap>
            <span>
              <img src={logo} alt="paypower" />
            </span>
          </Typography>
          <div style={style.grow} />
          <div style={style.sidebar}>
            <Box display="flex">
              <Typography
                variant="body1"
                component="span"
                style={{ alignSelf: "center" }}
              >
                {`Welcome Back, ${userState.user?.phonenumber}`}
              </Typography>
            </Box>
            <IconButton
              aria-controls="user-menu"
              aria-haspopup="true"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreVertOutlined style={{ color: "white" }} />
            </IconButton>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ zIndex: 10 }}
            >
              <Hidden smDown implementation="js">
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate({ pathname: "/user" });
                  }}
                >
                  <ListItemIcon>
                    <Avatar
                      alt={userState.user?.phonenumber || ""}
                      src={userState?.user?.profileImage || ""}
                      sx={{
                        marginRight: "5px",
                        marginLeft: "-6px",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="My Account" />
                </MenuItem>
              </Hidden>
              <MenuItem
                onClick={() => {
                  handleClose();
                  window.open("tel:+2348160000973");
                }}
              >
                <ListItemIcon>
                  <PhoneOutlined />
                </ListItemIcon>
                <ListItemText primary="Customer care" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  localStorage.removeItem("PPNG_TOKEN");
                  localStorage.removeItem("PPNG_ID");
                  window.location.href = "/auth/login?navigateTo=/dashboard";
                }}
              >
                <ListItemIcon>
                  <ExitToAppOutlined />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
            {/* <Button 
							onClick={()=>{
								localStorage.removeItem('PPNG_TOKEN');
								localStorage.removeItem('PPNG_ID');
								window.location = '/auth/login?navigateTo=/dashboard';
							}}
							color="inherit"
						>
							<ExitToAppOutlined/> 
							Logout
						</Button> */}
          </div>
        </Toolbar>
      </AppBar>
      <nav style={style.drawer} aria-label="mailbox folders">
        <Hidden smDown implementation="js">
          <Drawer sx={style.drawerPaper} variant="permanent" open>
            {DRAWER}
          </Drawer>
        </Hidden>
      </nav>
      <Hidden smUp implementation="js">
        <Paper elevation={3} />
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            navigate({ pathname: `/${newValue}` });
          }}
          sx={style.bottomNavigation}
        >
          <BottomNavigationAction
            label="Dashboard"
            value="dashboard"
            icon={<HomeOutlined />}
          />
          <BottomNavigationAction
            label="Transactions"
            value="transactions"
            icon={<HistoryOutlined />}
          />
          <BottomNavigationAction
            label="Settings"
            value="settings"
            icon={<SettingsOutlined />}
          />
        </BottomNavigation>
      </Hidden>
    </>
  );
}
