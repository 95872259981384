import responsiveFontSizes from "@mui/material/styles/responsiveFontSizes";
import createTheme from "@mui/material/styles/createTheme";

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: "Work Sans",
    },
    palette: {
      primary: {
        main: "#45B41D",
        contrastText: "white",
      },
      secondary: {
        main: "#E17F1E",
      },
      contrastThreshold: 3,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
            textTransform: "capitalize",
          },
        },
      },
    },
  })
);

export default theme;
