import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user";
import transactionsReducer from "./transactions";
import meternumbersReducer from "./meternumber";
import notificationReducer from "./notification";

export const store = configureStore({
  reducer: {
    user: userReducer,
    transactions: transactionsReducer,
    meternumbers: meternumbersReducer,
    notifications: notificationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
