import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { useGetUserLazyQuery, User } from "../generated/graphql";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { logIn, logOut } from "../state/user";
import IndexComponent from "../pages";

export default function AuthGuard() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user);

  const [getUser] = useGetUserLazyQuery({
    onCompleted(data) {
      if (data.getUser) {
        dispatch(logIn(data.getUser as unknown as User));
      } else {
        dispatch(logOut());
      }
    },
    onError(error) {
      dispatch(logOut());
      // eslint-disable-next-line no-console
      console.error(error);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!userState.user) {
      getUser();
    }
  }, [getUser, userState]);

  if (userState.loading) {
    return (
      <Box
        width="100%"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return userState.user ? (
    <IndexComponent>
      <Outlet />
    </IndexComponent>
  ) : (
    <Navigate
      to={{
        pathname: "auth/login",
        search: `?navigateTo=${
          location.pathname === "/" ? "/dashboard" : location.pathname
        }`,
      }}
      state={{
        referrer: location.pathname,
      }}
    />
  );
}
