import { useEffect, ReactElement, ReactNode } from "react";
import { useDispatch } from "react-redux";
import {
  useGetTransactionsLazyQuery,
  Transaction,
  useGetMeternumbersLazyQuery,
  Meternumber,
  useGetNotificationsLazyQuery,
  Notification,
} from "../generated/graphql";
import { setMeternumbers } from "../state/meternumber";
import { setNotifications } from "../state/notification";
import { setTransactions } from "../state/transactions";

const IndexComponent = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const dispatch = useDispatch();

  const [getTransactions] = useGetTransactionsLazyQuery({
    onCompleted(data) {
      dispatch(
        setTransactions(data.getTransactions as unknown as Transaction[])
      );
    },
  });

  const [getMeternumbers] = useGetMeternumbersLazyQuery({
    onCompleted(data) {
      dispatch(
        setMeternumbers(data.getMeternumbers as unknown as Meternumber[])
      );
    },
  });

  const [getNotifications] = useGetNotificationsLazyQuery({
    onCompleted(data) {
      dispatch(
        setNotifications(data.getNotifications as unknown as Notification[])
      );
    },
  });

  useEffect(() => {
    getTransactions();

    getMeternumbers();

    getNotifications();
  }, [getTransactions, getMeternumbers, getNotifications]);

  return <>{children}</>;
};

export default IndexComponent;
