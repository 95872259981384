import { ReactElement } from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export default function Privacy(): ReactElement {
  const paypower = (
    <Typography fontWeight={500} component="span">
      paypower.ng
    </Typography>
  );
  return (
    <>
      <Helmet>
        <title>PaypowerNG - Privacy policy</title>
      </Helmet>
      <List>
        <Typography fontWeight={500} variant="h5" align="center">
          Privacy policy
        </Typography>
        <Box py={1}>
          <Typography>
            Zarazite plus concept Limited operates {paypower}. This Privacy
            Policy informs you of the policies regarding the collection, use and
            disclosure of Personal Information when you use {paypower}. We will
            not share your information with anyone except as described in this
            Privacy Policy. We use your personal information for providing and
            improving the services on the Platform. By using the platform, you
            agree to the collection and use of information in accordance with
            this policy. Unless otherwise defined in this Privacy Policy, terms
            used in this Privacy Policy have the same meanings as in our Terms
            of Use. This Privacy Policy applies to all of our services. By
            accessing or using our services, or otherwise manifesting your
            assent to this Privacy Policy, you signify that you have read,
            understood and agree to our collection, storage, use and disclosure
            of your personal information as described in this Privacy Policy and
            our Terms of Use. If you are an individual acting on behalf of a
            Company, you represent and warrant that you are an authorized
            representative of Company with the authority to bind Company to this
            Privacy Policy, and that you agree to this Privacy Policy on
            Company’s behalf.
          </Typography>
        </Box>
        <Box py={1}>
          <Typography variant="h6">Information Collection and Use</Typography>
          <Typography>
            While using {paypower} we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally identifiable information may include, but
            is not limited to, your email address, name, phone number, address
            (“Personal Information”).
          </Typography>
        </Box>
        <Box py={1}>
          <Typography variant="h6">Analytics</Typography>
          <Typography>
            When you access {paypower} by or through a mobile device, we may
            collect certain information automatically, including, but not
            limited to, the type of mobile device you use, your mobile device
            unique ID, the IP address of your mobile device, your mobile
            operating system, the type of internet mobile browser you use, and
            other statistics (“Log Data”). In addition, we may use third party
            platforms such as Google Analytics, that collect, monitor and
            analyse this type of information in order to increase {paypower}
            functionality. These third-party platform providers have their own
            privacy policies addressing how they use such information.
          </Typography>
        </Box>
        <Box py={1}>
          <Typography variant="h6">Location</Typography>
          <Typography>
            We do not collect your location information at this time
          </Typography>
        </Box>
        <Box py={1}>
          <Typography variant="h6">What information do we collect?</Typography>
          <List sx={{ listStyle: "decimal", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                <Typography>
                  We collect personal information like your name, your address,
                  your phonenumber and your email
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
        <Box py={1}>
          <Typography variant="h6">Purpose for your data</Typography>
          <List sx={{ listStyle: "decimal", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                <Typography>
                  To provide you with information about other goods we offer
                  that are similar to those that you have already purchased or
                  enquired about.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                <Typography>
                  To ensure that content from our site is presented in the most
                  effective manner for you and to notify you about changes to
                  our Platform.
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
        <Box py={1}>
          <Typography variant="h6">Disclosure of your information</Typography>
          <List sx={{ listStyle: "decimal", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                <Typography>
                  Certain information is disclosed with selected third parties
                  including business partners, suppliers and subcontractors for
                  the performance of any contract we enter into with them or
                  you;
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                <Typography>
                  Certain informtion will be disclosed in response to a request
                  for information, if we are required by, or we believe
                  disclosure is in accordance with, any applicable law,
                  regulation or legal process
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
        <Box py={1}>
          <Typography variant="h6">
            How Your information is stored and protected
          </Typography>
          <Typography>
            Your information collected through our Services may be stored and
            processed in Nigeria. If you are located in the European Union or
            other regions with laws governing data collection and use that may
            differ from Nigeria law, please note that we may transfer
            information, including personal information, to a country and
            jurisdiction that does not have the same data protection laws as
            your jurisdiction, and you acknowledge such transfer of information
            to Nigeria or any other country in which the
            {paypower} or its parent, subsidiaries, affiliates, or service
            providers maintain facilities and the use and disclosure of
            information about you as described in this Privacy Policy.
          </Typography>
        </Box>
        <Box py={1}>
          <Typography variant="h6">Contact us</Typography>
          <Typography>
            If you have any questions about this Privacy Policy or the website,
            please contact us at{" "}
            <a href="mailto:zaraziteplusconcept@gmail.com">
              zaraziteplusconcept@gmail.com
            </a>
          </Typography>
        </Box>
      </List>
    </>
  );
}
