import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  FileUpload: any;
};

export type Account = {
  __typename?: 'Account';
  accountNumber?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reference?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Admin = {
  __typename?: 'Admin';
  accessLevel: Scalars['String'];
  email: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  phonenumber?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
};

export type AvailablePricingOptions = {
  __typename?: 'AvailablePricingOptions';
  invoicePeriod?: Maybe<Scalars['Int']>;
  monthsPaidFor?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
};

export enum Cable {
  Dstv = 'dstv',
  Gotv = 'gotv',
  Startimes = 'startimes'
}

export type CableType = {
  __typename?: 'CableType';
  availablePricingOptions?: Maybe<Array<Maybe<AvailablePricingOptions>>>;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type DataBundle = {
  __typename?: 'DataBundle';
  allowance?: Maybe<Scalars['String']>;
  datacode: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  validity?: Maybe<Scalars['String']>;
};

export enum Disco {
  AbujaElectric = 'abuja_electric',
  EkoElectric = 'eko_electric',
  EnuguElectric = 'enugu_electric',
  IbadanElectric = 'ibadan_electric',
  IkejaElectric = 'ikeja_electric',
  JosElectric = 'jos_electric',
  KadunaElectric = 'kaduna_electric',
  KedcoElectric = 'kedco_electric',
  PortharcourtElectric = 'portharcourt_electric'
}

export type Message = {
  __typename?: 'Message';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

export enum MeterType {
  Postpaid = 'postpaid',
  Prepaid = 'prepaid'
}

export type Meternumber = {
  __typename?: 'Meternumber';
  address?: Maybe<Scalars['String']>;
  distributor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPrepaid?: Maybe<Scalars['Boolean']>;
  lastActivity?: Maybe<Scalars['String']>;
  meternumber: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  buyAirtime: Message;
  buyCable: Message;
  buyDataBundle: Message;
  buyElectricity: Message;
  confirmEmail: Message;
  creditWallet: Message;
  deleteAccount: Message;
  deleteMeternumber: Message;
  forgotPassword: Message;
  login: Message;
  resetPassword: Message;
  sendPaymentInfo: Message;
  signup: Message;
  updateNotificationToken: Message;
  updateUser: Message;
};


export type MutationBuyAirtimeArgs = {
  amount: Scalars['Float'];
  network: Scalars['String'];
  paymentReference?: InputMaybe<Scalars['String']>;
  phonenumber: Scalars['String'];
};


export type MutationBuyCableArgs = {
  addonCode?: InputMaybe<Array<Scalars['String']>>;
  amount: Scalars['Float'];
  months?: InputMaybe<Scalars['Int']>;
  paymentReference?: InputMaybe<Scalars['String']>;
  productCode: Scalars['String'];
  serviceType: Cable;
  smartcard: Scalars['String'];
};


export type MutationBuyDataBundleArgs = {
  amount: Scalars['Float'];
  itemCode: Scalars['String'];
  network: Scalars['String'];
  paymentReference?: InputMaybe<Scalars['String']>;
  phonenumber: Scalars['String'];
};


export type MutationBuyElectricityArgs = {
  amount: Scalars['Float'];
  distributor: Disco;
  email: Scalars['String'];
  meterType: MeterType;
  meternumber: Scalars['String'];
  paymentReference?: InputMaybe<Scalars['String']>;
  phonenumber?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmEmailArgs = {
  query: Scalars['String'];
};


export type MutationCreditWalletArgs = {
  amount: Scalars['Float'];
};


export type MutationDeleteMeternumberArgs = {
  meternumberId: Scalars['ID'];
};


export type MutationForgotPasswordArgs = {
  phonenumber: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  phonenumber: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationSendPaymentInfoArgs = {
  paymentReference: Scalars['String'];
  transactionReference: Scalars['String'];
};


export type MutationSignupArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  phonenumber: Scalars['String'];
};


export type MutationUpdateNotificationTokenArgs = {
  notificationToken: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  date: Scalars['String'];
  description: Scalars['String'];
  header: Scalars['String'];
  id: Scalars['ID'];
};

export type PhonenumberInput = {
  amount: Scalars['Int'];
  network: Scalars['String'];
  phonenumber: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  checkUser: Scalars['Boolean'];
  exportCSV: Scalars['String'];
  getAccounts: Array<Account>;
  getCableAddons: Array<Maybe<CableType>>;
  getCableProducts: Array<Maybe<CableType>>;
  getDataBundles: Array<Maybe<DataBundle>>;
  getMeternumbers: Array<Meternumber>;
  getNotifications: Array<Notification>;
  getTransactions: Array<Transaction>;
  getUser: User;
  getWalletBalance: Scalars['Float'];
  queryCable: Scalars['String'];
  queryElectricity: Scalars['String'];
};


export type QueryCheckUserArgs = {
  phonenumber: Scalars['String'];
};


export type QueryExportCsvArgs = {
  category: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetCableAddonsArgs = {
  productCode: Scalars['String'];
  serviceType: Cable;
};


export type QueryGetCableProductsArgs = {
  provider: Cable;
};


export type QueryGetDataBundlesArgs = {
  network: Scalars['String'];
};


export type QueryQueryCableArgs = {
  serviceType: Cable;
  smartcard: Scalars['String'];
};


export type QueryQueryElectricityArgs = {
  distributor: Disco;
  meterType: MeterType;
  meternumber: Scalars['String'];
};

export enum Role {
  Admin = 'ADMIN',
  Merchant = 'MERCHANT',
  Unknown = 'UNKNOWN',
  User = 'USER'
}

export type Transaction = {
  __typename?: 'Transaction';
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  admin?: Maybe<Admin>;
  amount: Scalars['Float'];
  category: Scalars['String'];
  date: Scalars['String'];
  distributor?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPostpaid?: Maybe<Scalars['Boolean']>;
  meternumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  outstanding?: Maybe<Scalars['Float']>;
  phonenumber?: Maybe<Scalars['String']>;
  recieptNo: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tariff?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
  vat?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  accounts?: Maybe<Array<Maybe<Account>>>;
  address?: Maybe<Scalars['String']>;
  blocked: Scalars['Boolean'];
  createdAt: Scalars['String'];
  creditMe?: Maybe<Scalars['Float']>;
  debtCanIncur?: Maybe<Scalars['Float']>;
  deleted: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  meternumbers?: Maybe<Array<Maybe<Meternumber>>>;
  notificationToken?: Maybe<Scalars['String']>;
  phonenumber?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Float']>;
  totalAmountSpent?: Maybe<Scalars['Float']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  walletBalance?: Maybe<Scalars['Float']>;
  walletBalanceBefore?: Maybe<Scalars['Float']>;
};

export type BuyAirtimeMutationVariables = Exact<{
  phonenumber: Scalars['String'];
  network: Scalars['String'];
  amount: Scalars['Float'];
  paymentReference?: InputMaybe<Scalars['String']>;
}>;


export type BuyAirtimeMutation = { __typename?: 'Mutation', buyAirtime: { __typename?: 'Message', success: boolean, message: string } };

export type BuyCableMutationVariables = Exact<{
  serviceType: Cable;
  smartcard: Scalars['String'];
  productCode: Scalars['String'];
  amount: Scalars['Float'];
  addonCode?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  months?: InputMaybe<Scalars['Int']>;
  paymentReference?: InputMaybe<Scalars['String']>;
}>;


export type BuyCableMutation = { __typename?: 'Mutation', buyCable: { __typename?: 'Message', message: string, success: boolean, token?: string | null } };

export type BuyDataBundleMutationVariables = Exact<{
  itemCode: Scalars['String'];
  phonenumber: Scalars['String'];
  amount: Scalars['Float'];
  network: Scalars['String'];
  paymentReference?: InputMaybe<Scalars['String']>;
}>;


export type BuyDataBundleMutation = { __typename?: 'Mutation', buyDataBundle: { __typename?: 'Message', success: boolean, message: string } };

export type BuyElectricityMutationVariables = Exact<{
  meternumber: Scalars['String'];
  distributor: Disco;
  amount: Scalars['Float'];
  meterType: MeterType;
  email: Scalars['String'];
  phonenumber?: InputMaybe<Scalars['String']>;
  paymentReference?: InputMaybe<Scalars['String']>;
}>;


export type BuyElectricityMutation = { __typename?: 'Mutation', buyElectricity: { __typename?: 'Message', success: boolean, message: string, token?: string | null } };

export type CheckUserQueryVariables = Exact<{
  phonenumber: Scalars['String'];
}>;


export type CheckUserQuery = { __typename?: 'Query', checkUser: boolean };

export type ConfirmEmailMutationVariables = Exact<{
  query: Scalars['String'];
}>;


export type ConfirmEmailMutation = { __typename?: 'Mutation', confirmEmail: { __typename?: 'Message', message: string, success: boolean } };

export type ExportCsvQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  category: Scalars['String'];
}>;


export type ExportCsvQuery = { __typename?: 'Query', exportCSV: string };

export type ForgotPasswordMutationVariables = Exact<{
  phonenumber: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'Message', success: boolean, message: string } };

export type GetCableProductsQueryVariables = Exact<{
  provider: Cable;
}>;


export type GetCableProductsQuery = { __typename?: 'Query', getCableProducts: Array<{ __typename?: 'CableType', code: string, description?: string | null, name: string, availablePricingOptions?: Array<{ __typename?: 'AvailablePricingOptions', price?: number | null, monthsPaidFor?: number | null, invoicePeriod?: number | null } | null> | null } | null> };

export type GetCableAddonsQueryVariables = Exact<{
  serviceType: Cable;
  productCode: Scalars['String'];
}>;


export type GetCableAddonsQuery = { __typename?: 'Query', getCableAddons: Array<{ __typename?: 'CableType', name: string, description?: string | null, code: string, availablePricingOptions?: Array<{ __typename?: 'AvailablePricingOptions', invoicePeriod?: number | null, monthsPaidFor?: number | null, price?: number | null } | null> | null } | null> };

export type GetDataBundlesQueryVariables = Exact<{
  network: Scalars['String'];
}>;


export type GetDataBundlesQuery = { __typename?: 'Query', getDataBundles: Array<{ __typename?: 'DataBundle', name: string, price: number, datacode: string, validity?: string | null, allowance?: string | null } | null> };

export type GetMeternumbersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeternumbersQuery = { __typename?: 'Query', getMeternumbers: Array<{ __typename?: 'Meternumber', id: string, meternumber: string, address?: string | null, lastActivity?: string | null, distributor?: string | null, isPrepaid?: boolean | null }> };

export type GetNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationsQuery = { __typename?: 'Query', getNotifications: Array<{ __typename?: 'Notification', id: string, header: string, description: string, date: string }> };

export type GetTransactionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTransactionsQuery = { __typename?: 'Query', getTransactions: Array<{ __typename?: 'Transaction', id: string, status: string, category: string, distributor?: string | null, recieptNo: string, name?: string | null, meternumber?: string | null, address?: string | null, date: string, amount: number, phonenumber?: string | null, network?: string | null, token?: string | null, isPostpaid?: boolean | null, reference?: string | null, vat?: string | null, tariff?: string | null, units?: number | null, email?: string | null, outstanding?: number | null, accountNumber?: string | null }> };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', firstname?: string | null, lastname?: string | null, phonenumber?: string | null, email?: string | null, profileImage?: string | null, walletBalance?: number | null, creditMe?: number | null, progress?: number | null, address?: string | null, createdAt: string } };

export type LoginMutationVariables = Exact<{
  phonenumber: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'Message', success: boolean, message: string, token?: string | null } };

export type QueryCableQueryVariables = Exact<{
  smartcard: Scalars['String'];
  serviceType: Cable;
}>;


export type QueryCableQuery = { __typename?: 'Query', queryCable: string };

export type QueryQueryVariables = Exact<{
  meternumber: Scalars['String'];
  distributor: Disco;
  meterType: MeterType;
}>;


export type QueryQuery = { __typename?: 'Query', queryElectricity: string };

export type ResetPasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'Message', success: boolean, message: string } };

export type SendPaymentInfoMutationVariables = Exact<{
  transactionReference: Scalars['String'];
  paymentReference: Scalars['String'];
}>;


export type SendPaymentInfoMutation = { __typename?: 'Mutation', sendPaymentInfo: { __typename?: 'Message', message: string, success: boolean, token?: string | null } };

export type SignupMutationVariables = Exact<{
  email: Scalars['String'];
  phonenumber: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignupMutation = { __typename?: 'Mutation', signup: { __typename?: 'Message', success: boolean, message: string, token?: string | null } };

export type UpdateUserMutationVariables = Exact<{
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'Message', success: boolean, message: string } };


export const BuyAirtimeDocument = gql`
    mutation BuyAirtime($phonenumber: String!, $network: String!, $amount: Float!, $paymentReference: String) {
  buyAirtime(
    phonenumber: $phonenumber
    network: $network
    amount: $amount
    paymentReference: $paymentReference
  ) {
    success
    message
  }
}
    `;
export type BuyAirtimeMutationFn = Apollo.MutationFunction<BuyAirtimeMutation, BuyAirtimeMutationVariables>;

/**
 * __useBuyAirtimeMutation__
 *
 * To run a mutation, you first call `useBuyAirtimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyAirtimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyAirtimeMutation, { data, loading, error }] = useBuyAirtimeMutation({
 *   variables: {
 *      phonenumber: // value for 'phonenumber'
 *      network: // value for 'network'
 *      amount: // value for 'amount'
 *      paymentReference: // value for 'paymentReference'
 *   },
 * });
 */
export function useBuyAirtimeMutation(baseOptions?: Apollo.MutationHookOptions<BuyAirtimeMutation, BuyAirtimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuyAirtimeMutation, BuyAirtimeMutationVariables>(BuyAirtimeDocument, options);
      }
export type BuyAirtimeMutationHookResult = ReturnType<typeof useBuyAirtimeMutation>;
export type BuyAirtimeMutationResult = Apollo.MutationResult<BuyAirtimeMutation>;
export type BuyAirtimeMutationOptions = Apollo.BaseMutationOptions<BuyAirtimeMutation, BuyAirtimeMutationVariables>;
export const BuyCableDocument = gql`
    mutation BuyCable($serviceType: Cable!, $smartcard: String!, $productCode: String!, $amount: Float!, $addonCode: [String!], $months: Int, $paymentReference: String) {
  buyCable(
    serviceType: $serviceType
    smartcard: $smartcard
    productCode: $productCode
    amount: $amount
    addonCode: $addonCode
    months: $months
    paymentReference: $paymentReference
  ) {
    message
    success
    token
  }
}
    `;
export type BuyCableMutationFn = Apollo.MutationFunction<BuyCableMutation, BuyCableMutationVariables>;

/**
 * __useBuyCableMutation__
 *
 * To run a mutation, you first call `useBuyCableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyCableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyCableMutation, { data, loading, error }] = useBuyCableMutation({
 *   variables: {
 *      serviceType: // value for 'serviceType'
 *      smartcard: // value for 'smartcard'
 *      productCode: // value for 'productCode'
 *      amount: // value for 'amount'
 *      addonCode: // value for 'addonCode'
 *      months: // value for 'months'
 *      paymentReference: // value for 'paymentReference'
 *   },
 * });
 */
export function useBuyCableMutation(baseOptions?: Apollo.MutationHookOptions<BuyCableMutation, BuyCableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuyCableMutation, BuyCableMutationVariables>(BuyCableDocument, options);
      }
export type BuyCableMutationHookResult = ReturnType<typeof useBuyCableMutation>;
export type BuyCableMutationResult = Apollo.MutationResult<BuyCableMutation>;
export type BuyCableMutationOptions = Apollo.BaseMutationOptions<BuyCableMutation, BuyCableMutationVariables>;
export const BuyDataBundleDocument = gql`
    mutation BuyDataBundle($itemCode: String!, $phonenumber: String!, $amount: Float!, $network: String!, $paymentReference: String) {
  buyDataBundle(
    itemCode: $itemCode
    phonenumber: $phonenumber
    amount: $amount
    network: $network
    paymentReference: $paymentReference
  ) {
    success
    message
  }
}
    `;
export type BuyDataBundleMutationFn = Apollo.MutationFunction<BuyDataBundleMutation, BuyDataBundleMutationVariables>;

/**
 * __useBuyDataBundleMutation__
 *
 * To run a mutation, you first call `useBuyDataBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyDataBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyDataBundleMutation, { data, loading, error }] = useBuyDataBundleMutation({
 *   variables: {
 *      itemCode: // value for 'itemCode'
 *      phonenumber: // value for 'phonenumber'
 *      amount: // value for 'amount'
 *      network: // value for 'network'
 *      paymentReference: // value for 'paymentReference'
 *   },
 * });
 */
export function useBuyDataBundleMutation(baseOptions?: Apollo.MutationHookOptions<BuyDataBundleMutation, BuyDataBundleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuyDataBundleMutation, BuyDataBundleMutationVariables>(BuyDataBundleDocument, options);
      }
export type BuyDataBundleMutationHookResult = ReturnType<typeof useBuyDataBundleMutation>;
export type BuyDataBundleMutationResult = Apollo.MutationResult<BuyDataBundleMutation>;
export type BuyDataBundleMutationOptions = Apollo.BaseMutationOptions<BuyDataBundleMutation, BuyDataBundleMutationVariables>;
export const BuyElectricityDocument = gql`
    mutation BuyElectricity($meternumber: String!, $distributor: Disco!, $amount: Float!, $meterType: MeterType!, $email: String!, $phonenumber: String, $paymentReference: String) {
  buyElectricity(
    meternumber: $meternumber
    distributor: $distributor
    amount: $amount
    meterType: $meterType
    email: $email
    phonenumber: $phonenumber
    paymentReference: $paymentReference
  ) {
    success
    message
    token
  }
}
    `;
export type BuyElectricityMutationFn = Apollo.MutationFunction<BuyElectricityMutation, BuyElectricityMutationVariables>;

/**
 * __useBuyElectricityMutation__
 *
 * To run a mutation, you first call `useBuyElectricityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyElectricityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyElectricityMutation, { data, loading, error }] = useBuyElectricityMutation({
 *   variables: {
 *      meternumber: // value for 'meternumber'
 *      distributor: // value for 'distributor'
 *      amount: // value for 'amount'
 *      meterType: // value for 'meterType'
 *      email: // value for 'email'
 *      phonenumber: // value for 'phonenumber'
 *      paymentReference: // value for 'paymentReference'
 *   },
 * });
 */
export function useBuyElectricityMutation(baseOptions?: Apollo.MutationHookOptions<BuyElectricityMutation, BuyElectricityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuyElectricityMutation, BuyElectricityMutationVariables>(BuyElectricityDocument, options);
      }
export type BuyElectricityMutationHookResult = ReturnType<typeof useBuyElectricityMutation>;
export type BuyElectricityMutationResult = Apollo.MutationResult<BuyElectricityMutation>;
export type BuyElectricityMutationOptions = Apollo.BaseMutationOptions<BuyElectricityMutation, BuyElectricityMutationVariables>;
export const CheckUserDocument = gql`
    query CheckUser($phonenumber: String!) {
  checkUser(phonenumber: $phonenumber)
}
    `;

/**
 * __useCheckUserQuery__
 *
 * To run a query within a React component, call `useCheckUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserQuery({
 *   variables: {
 *      phonenumber: // value for 'phonenumber'
 *   },
 * });
 */
export function useCheckUserQuery(baseOptions: Apollo.QueryHookOptions<CheckUserQuery, CheckUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckUserQuery, CheckUserQueryVariables>(CheckUserDocument, options);
      }
export function useCheckUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckUserQuery, CheckUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckUserQuery, CheckUserQueryVariables>(CheckUserDocument, options);
        }
export type CheckUserQueryHookResult = ReturnType<typeof useCheckUserQuery>;
export type CheckUserLazyQueryHookResult = ReturnType<typeof useCheckUserLazyQuery>;
export type CheckUserQueryResult = Apollo.QueryResult<CheckUserQuery, CheckUserQueryVariables>;
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($query: String!) {
  confirmEmail(query: $query) {
    message
    success
  }
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const ExportCsvDocument = gql`
    query ExportCSV($startDate: String!, $endDate: String!, $category: String!) {
  exportCSV(startDate: $startDate, endDate: $endDate, category: $category)
}
    `;

/**
 * __useExportCsvQuery__
 *
 * To run a query within a React component, call `useExportCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCsvQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useExportCsvQuery(baseOptions: Apollo.QueryHookOptions<ExportCsvQuery, ExportCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCsvQuery, ExportCsvQueryVariables>(ExportCsvDocument, options);
      }
export function useExportCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCsvQuery, ExportCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCsvQuery, ExportCsvQueryVariables>(ExportCsvDocument, options);
        }
export type ExportCsvQueryHookResult = ReturnType<typeof useExportCsvQuery>;
export type ExportCsvLazyQueryHookResult = ReturnType<typeof useExportCsvLazyQuery>;
export type ExportCsvQueryResult = Apollo.QueryResult<ExportCsvQuery, ExportCsvQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($phonenumber: String!) {
  forgotPassword(phonenumber: $phonenumber) {
    success
    message
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      phonenumber: // value for 'phonenumber'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const GetCableProductsDocument = gql`
    query GetCableProducts($provider: Cable!) {
  getCableProducts(provider: $provider) {
    code
    description
    name
    availablePricingOptions {
      price
      monthsPaidFor
      invoicePeriod
    }
  }
}
    `;

/**
 * __useGetCableProductsQuery__
 *
 * To run a query within a React component, call `useGetCableProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCableProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCableProductsQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useGetCableProductsQuery(baseOptions: Apollo.QueryHookOptions<GetCableProductsQuery, GetCableProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCableProductsQuery, GetCableProductsQueryVariables>(GetCableProductsDocument, options);
      }
export function useGetCableProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCableProductsQuery, GetCableProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCableProductsQuery, GetCableProductsQueryVariables>(GetCableProductsDocument, options);
        }
export type GetCableProductsQueryHookResult = ReturnType<typeof useGetCableProductsQuery>;
export type GetCableProductsLazyQueryHookResult = ReturnType<typeof useGetCableProductsLazyQuery>;
export type GetCableProductsQueryResult = Apollo.QueryResult<GetCableProductsQuery, GetCableProductsQueryVariables>;
export const GetCableAddonsDocument = gql`
    query GetCableAddons($serviceType: Cable!, $productCode: String!) {
  getCableAddons(serviceType: $serviceType, productCode: $productCode) {
    name
    description
    code
    availablePricingOptions {
      invoicePeriod
      monthsPaidFor
      price
    }
  }
}
    `;

/**
 * __useGetCableAddonsQuery__
 *
 * To run a query within a React component, call `useGetCableAddonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCableAddonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCableAddonsQuery({
 *   variables: {
 *      serviceType: // value for 'serviceType'
 *      productCode: // value for 'productCode'
 *   },
 * });
 */
export function useGetCableAddonsQuery(baseOptions: Apollo.QueryHookOptions<GetCableAddonsQuery, GetCableAddonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCableAddonsQuery, GetCableAddonsQueryVariables>(GetCableAddonsDocument, options);
      }
export function useGetCableAddonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCableAddonsQuery, GetCableAddonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCableAddonsQuery, GetCableAddonsQueryVariables>(GetCableAddonsDocument, options);
        }
export type GetCableAddonsQueryHookResult = ReturnType<typeof useGetCableAddonsQuery>;
export type GetCableAddonsLazyQueryHookResult = ReturnType<typeof useGetCableAddonsLazyQuery>;
export type GetCableAddonsQueryResult = Apollo.QueryResult<GetCableAddonsQuery, GetCableAddonsQueryVariables>;
export const GetDataBundlesDocument = gql`
    query GetDataBundles($network: String!) {
  getDataBundles(network: $network) {
    name
    price
    datacode
    validity
    allowance
  }
}
    `;

/**
 * __useGetDataBundlesQuery__
 *
 * To run a query within a React component, call `useGetDataBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataBundlesQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useGetDataBundlesQuery(baseOptions: Apollo.QueryHookOptions<GetDataBundlesQuery, GetDataBundlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataBundlesQuery, GetDataBundlesQueryVariables>(GetDataBundlesDocument, options);
      }
export function useGetDataBundlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataBundlesQuery, GetDataBundlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataBundlesQuery, GetDataBundlesQueryVariables>(GetDataBundlesDocument, options);
        }
export type GetDataBundlesQueryHookResult = ReturnType<typeof useGetDataBundlesQuery>;
export type GetDataBundlesLazyQueryHookResult = ReturnType<typeof useGetDataBundlesLazyQuery>;
export type GetDataBundlesQueryResult = Apollo.QueryResult<GetDataBundlesQuery, GetDataBundlesQueryVariables>;
export const GetMeternumbersDocument = gql`
    query GetMeternumbers {
  getMeternumbers {
    id
    meternumber
    address
    lastActivity
    distributor
    isPrepaid
  }
}
    `;

/**
 * __useGetMeternumbersQuery__
 *
 * To run a query within a React component, call `useGetMeternumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeternumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeternumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeternumbersQuery(baseOptions?: Apollo.QueryHookOptions<GetMeternumbersQuery, GetMeternumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeternumbersQuery, GetMeternumbersQueryVariables>(GetMeternumbersDocument, options);
      }
export function useGetMeternumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeternumbersQuery, GetMeternumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeternumbersQuery, GetMeternumbersQueryVariables>(GetMeternumbersDocument, options);
        }
export type GetMeternumbersQueryHookResult = ReturnType<typeof useGetMeternumbersQuery>;
export type GetMeternumbersLazyQueryHookResult = ReturnType<typeof useGetMeternumbersLazyQuery>;
export type GetMeternumbersQueryResult = Apollo.QueryResult<GetMeternumbersQuery, GetMeternumbersQueryVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications {
  getNotifications {
    id
    header
    description
    date
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const GetTransactionsDocument = gql`
    query GetTransactions {
  getTransactions {
    id
    status
    category
    distributor
    recieptNo
    name
    meternumber
    address
    date
    amount
    phonenumber
    network
    token
    isPostpaid
    reference
    vat
    tariff
    units
    email
    outstanding
    accountNumber
  }
}
    `;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
      }
export function useGetTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
        }
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  getUser {
    firstname
    lastname
    phonenumber
    email
    profileImage
    walletBalance
    creditMe
    progress
    address
    createdAt
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const LoginDocument = gql`
    mutation Login($phonenumber: String!, $password: String!) {
  login(phonenumber: $phonenumber, password: $password) {
    success
    message
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      phonenumber: // value for 'phonenumber'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const QueryCableDocument = gql`
    query QueryCable($smartcard: String!, $serviceType: Cable!) {
  queryCable(smartcard: $smartcard, serviceType: $serviceType)
}
    `;

/**
 * __useQueryCableQuery__
 *
 * To run a query within a React component, call `useQueryCableQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCableQuery({
 *   variables: {
 *      smartcard: // value for 'smartcard'
 *      serviceType: // value for 'serviceType'
 *   },
 * });
 */
export function useQueryCableQuery(baseOptions: Apollo.QueryHookOptions<QueryCableQuery, QueryCableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryCableQuery, QueryCableQueryVariables>(QueryCableDocument, options);
      }
export function useQueryCableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryCableQuery, QueryCableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryCableQuery, QueryCableQueryVariables>(QueryCableDocument, options);
        }
export type QueryCableQueryHookResult = ReturnType<typeof useQueryCableQuery>;
export type QueryCableLazyQueryHookResult = ReturnType<typeof useQueryCableLazyQuery>;
export type QueryCableQueryResult = Apollo.QueryResult<QueryCableQuery, QueryCableQueryVariables>;
export const QueryDocument = gql`
    query Query($meternumber: String!, $distributor: Disco!, $meterType: MeterType!) {
  queryElectricity(
    meternumber: $meternumber
    distributor: $distributor
    meterType: $meterType
  )
}
    `;

/**
 * __useQueryQuery__
 *
 * To run a query within a React component, call `useQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQuery({
 *   variables: {
 *      meternumber: // value for 'meternumber'
 *      distributor: // value for 'distributor'
 *      meterType: // value for 'meterType'
 *   },
 * });
 */
export function useQueryQuery(baseOptions: Apollo.QueryHookOptions<QueryQuery, QueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryQuery, QueryQueryVariables>(QueryDocument, options);
      }
export function useQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryQuery, QueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryQuery, QueryQueryVariables>(QueryDocument, options);
        }
export type QueryQueryHookResult = ReturnType<typeof useQueryQuery>;
export type QueryLazyQueryHookResult = ReturnType<typeof useQueryLazyQuery>;
export type QueryQueryResult = Apollo.QueryResult<QueryQuery, QueryQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($oldPassword: String!, $newPassword: String!) {
  resetPassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    success
    message
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendPaymentInfoDocument = gql`
    mutation SendPaymentInfo($transactionReference: String!, $paymentReference: String!) {
  sendPaymentInfo(
    transactionReference: $transactionReference
    paymentReference: $paymentReference
  ) {
    message
    success
    token
  }
}
    `;
export type SendPaymentInfoMutationFn = Apollo.MutationFunction<SendPaymentInfoMutation, SendPaymentInfoMutationVariables>;

/**
 * __useSendPaymentInfoMutation__
 *
 * To run a mutation, you first call `useSendPaymentInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPaymentInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPaymentInfoMutation, { data, loading, error }] = useSendPaymentInfoMutation({
 *   variables: {
 *      transactionReference: // value for 'transactionReference'
 *      paymentReference: // value for 'paymentReference'
 *   },
 * });
 */
export function useSendPaymentInfoMutation(baseOptions?: Apollo.MutationHookOptions<SendPaymentInfoMutation, SendPaymentInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPaymentInfoMutation, SendPaymentInfoMutationVariables>(SendPaymentInfoDocument, options);
      }
export type SendPaymentInfoMutationHookResult = ReturnType<typeof useSendPaymentInfoMutation>;
export type SendPaymentInfoMutationResult = Apollo.MutationResult<SendPaymentInfoMutation>;
export type SendPaymentInfoMutationOptions = Apollo.BaseMutationOptions<SendPaymentInfoMutation, SendPaymentInfoMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($email: String!, $phonenumber: String!, $password: String!) {
  signup(email: $email, phonenumber: $phonenumber, password: $password) {
    success
    message
    token
  }
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phonenumber: // value for 'phonenumber'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($firstname: String!, $lastname: String!, $email: String!) {
  updateUser(firstname: $firstname, lastname: $lastname, email: $email) {
    success
    message
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;