import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { SnackbarProvider } from "notistack";
import "./index.css";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import client from "./client";
import { store } from "./state";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://87c9ca29ee2043a6b484e6157d256be5@o4504713211084800.ingest.sentry.io/4504754763464704",
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate:
    process.env.REACT_APP_ENVIRONMENT === "development" ? 1.0 : 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router>
        <SnackbarProvider
          maxSnack={7}
          style={{
            fontFamily: "Work Sans",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <App />
        </SnackbarProvider>
      </Router>
    </Provider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
