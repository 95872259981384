import "./index.css";

import { useState, useEffect } from "react";
import { Formik } from "formik";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useMonnifyPayment } from "react-monnify";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";

import CloseOutlined from "@mui/icons-material/CloseOutlined";

import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import theme from "../../theme";
import Transition from "../../shared/components/transition";
import {
  useGetCableProductsLazyQuery,
  CableType,
  Cable,
  useGetCableAddonsLazyQuery,
  useBuyCableMutation,
  useSendPaymentInfoMutation,
  useQueryCableLazyQuery,
} from "../../generated/graphql";
import providers from "../../shared/constants/cableProviders";
import { useAppSelector } from "../../state/hooks";

interface FormValues {
  name?: string;
  smartcard: string;
  provider: string;
  productCode: string;
  amount: number;
  addons: Array<string>;
  month: number;
}

const style = {
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: 150,
    [theme.breakpoints.up(600 + parseInt(theme.spacing(2), 10) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + parseInt(theme.spacing(3), 10) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  textField: {
    marginTop: "8px",
    borderRadius: "5px",
  },
};

export default function BuyCable() {
  const { enqueueSnackbar } = useSnackbar();

  const [open, openDialog] = useState(false);
  const [reference, setReference] = useState(
    `PPNG-${Math.floor(Math.random() * 1000000000 + 1)}`
  );
  const [loading, setLoading] = useState(false);
  const [preFormValues, setPreFormValues] = useState<FormValues | undefined>(
    undefined
  );
  const [formValues, setFormValues] = useState<FormValues | undefined>(
    undefined
  );
  const userState = useAppSelector((state) => state.user);
  const [cableProducts, setCableProducts] = useState<Array<CableType>>([]);
  const [addons, setAddons] = useState<Array<CableType>>([]);

  const [getCableProducts] = useGetCableProductsLazyQuery({
    fetchPolicy: "cache-first",
    onCompleted(data) {
      setCableProducts(data.getCableProducts as unknown as CableType[]);
    },
  });

  const [getAddons] = useGetCableAddonsLazyQuery({
    fetchPolicy: "cache-first",
    onCompleted(data) {
      setAddons(data.getCableAddons as unknown as CableType[]);
    },
  });

  const [queryCable] = useQueryCableLazyQuery({
    onCompleted(data) {
      const query: {
        status: string;
        message: string;
        code?: number;
        data: Record<string, Record<string, string | Record<string, string>>>;
      } = JSON.parse(data.queryCable);

      if (query.status === "success") {
        if (typeof preFormValues !== "undefined") {
          setFormValues({
            ...preFormValues,
            name: query.data.user.name as string,
          });
        }
      } else {
        openDialog(false);
        setPreFormValues(undefined);
        setFormValues(undefined);
        enqueueSnackbar(query.message, {
          variant: "error",
        });
      }
    },
    onError(error) {
      openDialog(false);
      setPreFormValues(undefined);
      setFormValues(undefined);
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    fetchPolicy: "network-only",
  });

  const [buyCable] = useBuyCableMutation({
    fetchPolicy: "network-only",
  });

  const [sendPaymentInfo] = useSendPaymentInfoMutation({
    fetchPolicy: "network-only",
  });

  const initialValues: FormValues = {
    smartcard: "",
    provider: "dstv",
    productCode: "",
    amount: 0.0,
    addons: [],
    month: 1,
  };

  const handleClose = () => {
    if (!loading) {
      openDialog(false);
      setFormValues(undefined);
    }
  };

  useEffect(() => {
    getCableProducts({
      variables: {
        provider: initialValues.provider as Cable,
      },
    });
  }, []);

  useEffect(() => {
    if (typeof formValues !== "undefined") {
      openDialog(true);
    }
  }, [formValues]);

  const initializePayment = useMonnifyPayment({
    currency: "NGN",
    reference,
    customerFullName: `${userState.user?.firstname} ${userState.user?.lastname}`,
    customerEmail: userState.user?.email ?? `zaraziteplusconcept@gmail.com`,
    customerMobileNumber: userState.user?.phonenumber ?? "",
    apiKey: process.env.REACT_APP_MONNIFY_API_KEY as string,
    contractCode: process.env.REACT_APP_MONNIFY_CONTRACT_CODE as string,
    isTestMode: process.env.REACT_APP_ENVIRONMENT === "development",
    paymentDescription: "Cable subscription purchase",
    amount: formValues?.amount as number,
  });

  const purchaseSubscription = async (
    paymentReference: string
  ): Promise<void> => {
    // if (typeof formValues === "undefined") return;

    // openDialog(true);
    if (!formValues || !formValues.productCode) {
      openDialog(false);
      setPreFormValues(undefined);
      setFormValues(undefined);
      enqueueSnackbar("Unable to complete purchase, Please reload page", {
        variant: "error",
      });
      return;
    }

    setLoading(true);

    try {
      const data = await buyCable({
        variables: {
          serviceType: formValues.provider as Cable,
          smartcard: formValues.smartcard,
          productCode: formValues.productCode,
          amount: formValues.amount,
          addonCode: formValues.addons,
          months: formValues.month,
          paymentReference,
        },
      });

      setLoading(false);
      openDialog(false);
      setPreFormValues(undefined);
      setFormValues(undefined);

      if (!data.errors) {
        if (data.data?.buyCable.success) {
          enqueueSnackbar(data.data?.buyCable.message);
          return;
        }

        enqueueSnackbar(data.data?.buyCable.message, {
          variant: "error",
        });
        return;
      }

      enqueueSnackbar(data.errors[0]?.originalError?.message);
    } catch (e) {
      setLoading(false);
      enqueueSnackbar("Something went wrong, Please try again");
    }
  };

  const completePurchase = () => {
    initializePayment(
      (response: Record<string, string>) => {
        if (response.status === "USER_CANCELLED") {
          setReference(`PPNG-${Math.floor(Math.random() * 1000000000 + 1)}`);
          enqueueSnackbar("Transaction cancelled", {
            variant: "error",
          });

          return;
        }

        if (response.status === "FAILED") {
          setReference(`PPNG-${Math.floor(Math.random() * 1000000000 + 1)}`);
          enqueueSnackbar(response.errorMessage, {
            variant: "error",
          });

          return;
        }

        if (response.status === "SUCCESS") {
          setLoading(true);

          sendPaymentInfo({
            variables: {
              paymentReference: response.paymentReference,
              transactionReference: response.transactionReference,
            },
          }).finally(() => {
            purchaseSubscription(response.transactionReference);
          });
        } else {
          setReference(`PPNG-${Math.floor(Math.random() * 1000000000 + 1)}`);
          enqueueSnackbar(
            "Unable to complete transaction, Please try again later",
            {
              variant: "error",
            }
          );
        }
      },
      () => {
        setReference(`PPNG-${Math.floor(Math.random() * 1000000000 + 1)}`);
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>Pay for cable - PaypowerNG</title>
      </Helmet>
      <Dialog
        open={open}
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": { borderRadius: "10px 10px 0px 0px" },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              onClick={handleClose}
              sx={{
                borderRadius: "50px",
                backgroundColor: "rgba(51, 51, 51, 0.15)",
                height: "1em",
                width: "1em",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <CloseOutlined
                sx={{ color: "white", height: "0.7em", width: "0.7em" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="body2" fontWeight={500}>
              Confirm purchase
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <List>
            <Grid container>
              <Grid item xs={6}>
                {" "}
                <Typography variant="caption" fontWeight={400}>
                  Type
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" fontWeight={400}>
                  Cable subscription
                </Typography>
              </Grid>
            </Grid>

            <Box padding="2px" />

            <Grid container>
              <Grid item xs={6}>
                {" "}
                <Typography variant="caption" fontWeight={400}>
                  Name
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" fontWeight={400}>
                  {formValues?.name}
                </Typography>
              </Grid>
            </Grid>

            <Box padding="2px" />

            <Grid container>
              <Grid item xs={6}>
                {" "}
                <Typography variant="caption" fontWeight={400}>
                  Cable Provider
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                >
                  {
                    providers.find(({ name }) => name === formValues?.provider)
                      ?.logo
                  }
                </Box>
              </Grid>
            </Grid>

            <Box padding="2px" />

            <Grid container>
              <Grid item xs={6}>
                {" "}
                <Typography variant="caption" fontWeight={400}>
                  Package
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="caption"
                  fontWeight={400}
                  color="rgba(69, 180, 29, 1)"
                >
                  {
                    cableProducts.find(
                      ({ code }) => code === formValues?.productCode
                    )?.name
                  }
                </Typography>
              </Grid>
            </Grid>

            <Box padding="2px" />

            <Grid container>
              <Grid item xs={6}>
                {" "}
                <Typography variant="caption" fontWeight={400}>
                  Smartcard number
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" fontWeight={400}>
                  {formValues?.smartcard}
                </Typography>
              </Grid>
            </Grid>

            <Box padding="2px" />

            <Grid container>
              <Grid item xs={6}>
                {" "}
                <Typography variant="caption" fontWeight={400}>
                  Months
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" fontWeight={400}>
                  {formValues?.month}
                </Typography>
              </Grid>
            </Grid>

            <Box padding="2px" />

            <Grid container>
              <Grid item xs={6}>
                {" "}
                <Typography variant="caption" fontWeight={400}>
                  Amount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" fontWeight={400}>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "NGN",
                  }).format(formValues?.amount || 0.0)}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              sx={{
                width: "100%",
                margin: theme.spacing(3, 0, 2),
                padding: theme.spacing(1, 3),
              }}
              onClick={completePurchase}
            >
              {loading ? (
                <CircularProgress
                  variant="indeterminate"
                  sx={{ color: "white" }}
                />
              ) : (
                "confirm"
              )}
            </Button>
          </List>
        </DialogContent>
      </Dialog>
      <Grid container>
        <Grid item xs={12}>
          <main style={style.layout}>
            <Paper elevation={4} sx={style.paper}>
              <Typography />
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  smartcard: Yup.string().required(
                    "Smartcard number is required"
                  ),
                  provider: Yup.string().required("Cable provider is required"),
                  productCode: Yup.string().required(
                    "Product code is required"
                  ),
                  month: Yup.number().required("Months are required"),
                })}
                onSubmit={async (values) => {
                  setPreFormValues({
                    ...values,
                    amount:
                      cableProducts
                        .filter(
                          (product) => product.code === values.productCode
                        )[0]
                        .availablePricingOptions?.find(
                          (option) => option?.monthsPaidFor === values.month
                        )?.price || 0.0,
                  });

                  await queryCable({
                    variables: {
                      smartcard: values.smartcard,
                      serviceType: values.provider as Cable,
                    },
                  });
                }}
              >
                {({
                  errors,
                  setFieldValue,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  isSubmitting,
                  isValid,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <InputLabel>
                        <Typography variant="subtitle2" fontWeight={400}>
                          Smartcard number
                        </Typography>
                      </InputLabel>
                      <TextField
                        id="smartcard"
                        variant="outlined"
                        margin="normal"
                        required
                        type="text"
                        fullWidth
                        name="smartcard"
                        placeholder="Enter the smartcard number"
                        sx={style.textField}
                        value={values.smartcard.trim().replace(/\s/g, "")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.smartcard && errors.smartcard)}
                        helperText={touched.smartcard && errors.smartcard}
                        autoComplete="smartcard"
                      />
                    </>
                    <Box padding="10px" />
                    <>
                      <InputLabel>
                        <Typography variant="subtitle2" fontWeight={400}>
                          Cable Provider
                        </Typography>
                        <Typography
                          variant="caption"
                          color="rgba(51, 51, 51, 0.2)"
                        >
                          Select the cable provider
                        </Typography>
                      </InputLabel>
                      <Box padding="5px" />
                      <Stack direction="row" spacing={2}>
                        {providers.map(({ id, logo, name }) => (
                          <Box
                            key={id}
                            style={{
                              height: "70px",
                              width: "70px",
                              cursor: "pointer",
                              border:
                                values.provider === name
                                  ? `2px solid rgba(69, 180, 29, 1)`
                                  : `1px solid rgba(51, 51, 51, 0.2)`,
                              borderRadius: "5px",
                              padding: "10px 10px 0px 10px",
                            }}
                            onClick={() => {
                              if (name === values.provider) return;

                              setFieldValue("provider", name as string);
                              setFieldValue("addons", []);
                              setFieldValue("productCode", "");
                              setAddons([]);
                              getCableProducts({
                                variables: {
                                  provider: name as Cable,
                                },
                              });
                            }}
                          >
                            {logo}
                          </Box>
                        ))}
                      </Stack>
                    </>
                    <Box padding="15px" />
                    <>
                      <InputLabel>
                        <Typography variant="subtitle2" fontWeight={400}>
                          Subscription package
                        </Typography>
                      </InputLabel>
                      <Box padding="5px" />
                      <Select
                        id="productCode"
                        fullWidth
                        name="productCode"
                        onChange={(e) => {
                          handleChange(e);

                          getAddons({
                            variables: {
                              serviceType: values.provider as Cable,
                              productCode: e.target.value,
                            },
                          });
                        }}
                        onBlur={handleBlur}
                        value={values.productCode}
                        error={Boolean(
                          touched.productCode && errors.productCode
                        )}
                      >
                        {cableProducts.map((option) => (
                          <MenuItem value={option.code} key={option.code}>
                            <ListItemText>
                              <Typography
                                variant="subtitle2"
                                fontWeight={400}
                                component="span"
                              >
                                {option.name}
                              </Typography>
                            </ListItemText>
                          </MenuItem>
                        ))}
                      </Select>

                      {values.productCode &&
                        cableProducts.filter(
                          (product) => product.code === values.productCode
                        ).length >= 1 && (
                          <>
                            <Box padding="15px" />
                            <>
                              <InputLabel>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={400}
                                >
                                  How many months?
                                </Typography>
                              </InputLabel>
                              <Box padding="5px" />
                              <Select
                                id="month"
                                fullWidth
                                name="month"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.month}
                                error={Boolean(touched.month && errors.month)}
                              >
                                {cableProducts
                                  .filter(
                                    (product) =>
                                      product.code === values.productCode
                                  )[0]
                                  .availablePricingOptions?.map((option) => (
                                    <MenuItem
                                      value={option?.monthsPaidFor as number}
                                      key={option?.monthsPaidFor}
                                    >
                                      <ListItemText>
                                        <Typography
                                          variant="subtitle2"
                                          fontWeight={400}
                                          component="span"
                                        >
                                          {option?.monthsPaidFor}{" "}
                                          {`month${
                                            option?.monthsPaidFor &&
                                            option.monthsPaidFor > 1
                                              ? "s"
                                              : ""
                                          }`}{" "}
                                          -{" "}
                                          {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "NGN",
                                          }).format(option?.price || 0.0)}
                                        </Typography>
                                      </ListItemText>
                                    </MenuItem>
                                  ))}
                              </Select>
                            </>
                          </>
                        )}
                    </>
                    {addons.length > 0 && (
                      <>
                        <Box padding="15px" />
                        <>
                          <InputLabel>
                            <Typography variant="subtitle2" fontWeight={400}>
                              Addons
                            </Typography>
                          </InputLabel>
                          <Autocomplete
                            multiple
                            id="addons"
                            onChange={(event, value) => {
                              setFieldValue("addons", value);
                            }}
                            options={addons}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            renderOption={(props, option) => (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              <ListItem key={option.name} {...props}>
                                <ListItemText primary={option.name} />
                              </ListItem>
                            )}
                            renderInput={(params) => (
                              <TextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                variant="outlined"
                                margin="normal"
                                placeholder="Please select addons"
                                autoComplete="off"
                              />
                            )}
                          />
                        </>
                      </>
                    )}
                    <Box padding="10px" />
                    <Box display="flex" justifyContent="center">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={isSubmitting || !isValid}
                        sx={{
                          width: "100%",
                          margin: theme.spacing(3, 0, 2),
                          padding: theme.spacing(1.5, 3),
                        }}
                      >
                        {isSubmitting ? (
                          <CircularProgress
                            variant="indeterminate"
                            sx={{ color: "white" }}
                          />
                        ) : (
                          "enter"
                        )}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Paper>
          </main>
        </Grid>
      </Grid>
    </>
  );
}
